import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import DynamicImage from "../dynamic-image"

const Container = styled(props => <Link {...props} />)`
  height: 386px;
  width: 328px;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  /* @media screen and (max-width: 1079px) {
    width: 504px;
  } */
  /* @media screen and (max-width: 599px) {
    width: 328px;
  } */
`

const ContainerText = styled.div`
  /* padding: 1rem; */
  height: 202px;
  padding: 24px 24px 24px 24px;
`

const Title = styled.p`
  max-height: 72px;
  max-width: 280px;
  color: #222222;
  font-size: 26px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 4px;
  display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const CompanyName = styled.p`
  height: 19px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #222222;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 4px;
`

const Description = styled.p`
  color: #222222;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 4px;
  display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

const Tag = styled.span`
  display: inline-block;
  /* height: 24px; */
  padding: 0px 12px 0px 12px;
  margin-right: 4px;
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.28px;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  background-color: #efefef;
  /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15); */
`

const ContainerImage = styled.div`
  display: flex;
  width: 100%;
  height: 184px;
  /* align-items: flex-end;
  vertical-align: bottom; */
`

const CasestudyLink = (props) => {
  const slug = props.slug
  const tags = props.tags
  const cardData = props.cardData
  const title = cardData.title
  const companyName = cardData.companyName
  const description = cardData.description
  const image = cardData.image

  return (
    // <Container to={`/case/${slug}`}>
    <Container to={`/case/${slug}`}>
      <ContainerText>
        <CompanyName>{companyName}</CompanyName>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {
          tags.map(
            tag => {
              const name = tag.name
              const key = tag.contentful_id
              return <Tag key={key}>{name}</Tag>
            }
          )
        }
      </ContainerText>
      <ContainerImage>
        <DynamicImage image={image} />
      </ContainerImage>
    </Container>
  )
}

export default CasestudyLink
